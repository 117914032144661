.integration-model {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__title {
    font-size: 20px;
    margin: 2.3rem auto 0 auto;
    text-align: center;
  }
}

.integration-model__button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: -3% !important;
  margin-top: 3% !important;

  &--btn {
    border: none;
    font-size: 1.6rem;
    padding: 1.3rem 3rem;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
  }

  &--save {
    background: #286fd1;
    width: 182px;
    color: #fff;
    border-radius: 6px !important;
    border: none;
    cursor: pointer;
    padding: 1.3rem 2.8rem !important;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #286fd1 !important;
    }
  }

  &--cancel {
    background: #fff;
    color: #000;
    border: 1px solid #000;
  }
}

.input-icon {
  margin-bottom: 1.5rem;

  &__title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    &--svg {
      display: flex;
      align-items: center;
    }

    &--label {
      font-size: 1.6rem !important;
      font-weight: 700;
      color: #27272e;
    }
  }

  &__form {
    &--input {
      border: 1px solid #d2d4d8 !important;

      &:active,
      &:focus,
      &:hover {
        box-shadow: none !important;
        border: 1px solid #d2d4d8 !important;
      }
    }
  }
}

.integration-model_width {
  width: 40% !important;
}

.hr_width {
  width: 100%;
  margin: 24px 0px !important;
}

.form_margin {
  margin-top: 15px;
}

.integration-model_title {
  margin-top: -20px;
  margin-bottom: 20px;
}

.tag-attribute {
  width: 100%;
  height: 42px;
  display: flex;
  place-items: center;

  &--button {
    font-size: 11px !important;
  }
  &--view_docs {
    margin-top: 2.6px;
  }
}
