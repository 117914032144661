.EmailSupport-model {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    margin-top: -0.5rem;
    margin-left: -40.75rem;
  }

  &__title {
    font-size: 26px;
    letter-spacing: -0.13px;
    width: 400px;
    margin: 2.3rem auto 0 auto;
    text-align: center;
  }
}

.EmailSupport-model_title {
  margin-top: -20px;
  margin-bottom: 20px;
}

.EmailSupport-model__button {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;

  &--btn {
    border: none;
    font-size: 1.8rem;
    padding: 1.3rem 3rem;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
  }
  &--save {
    background: #286fd1;
    width: 142px;
    color: #fff;

    border-radius: 6px !important;
    border: none;
    cursor: pointer;

    &:hover {
      background: #286fd1 !important;
    }
  }
  &--cancel {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    width: 142px;
  }
}

.tag-attribute {
  width: 100%;
  height: 35px;
  display: flex;
  place-items: center;
margin-bottom: 5px;}
  

.input-icon {
  margin-bottom: 1.5rem;
  &__title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    &--svg {
      display: flex;
      align-items: center;
    }
    &--label {
      font-size: 1.6rem !important;
      font-weight: 700;
      color: #27272e;
    }
  }
  &__form {
    &--input {
      border: 1px solid #d2d4d8 !important;
      &:active,
      &:focus,
      &:hover {
        box-shadow: none !important;
        border: 1px solid #d2d4d8 !important;
      }
    }
  }
}
.phoneError{
	color: #ff4d4f !important;
}
