.global-layout {
  height: 100vh;

  &__body {
    display: flex;
    height: calc(100vh - 74px);
    &--body {
      width: 100%;
      background: #f3f5f7;
      padding: 2.1rem 3rem 3.6rem 3rem;
    }
  }
}
