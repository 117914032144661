.dynamic-permission-table {
	padding-left: 3.6rem;
	padding-right: 3.6rem;
}
.side-drawer-form {
	padding: 0px 32px 32px 32px;
	&__buttons {
		display: flex;
		align-items: center;
		gap: 2rem;

		margin-top: 3rem;
		&--btn {
			border: none;
			font-size: 1.6rem;
			padding: 1.3rem 2.8rem;
			border-radius: 6px;
			cursor: pointer;
			font-weight: 600 !important;
		}
		&--save {
			background: #286FD1;
			color: #fff;
			border: 1px solid #286FD1;
			width: 127px;
			
		}
		&--cancel {
			background: #fff;
			color: #000;
			border: 1px solid #000;
			width: 127px;
		}
	}
}
