.qbo-account-form {
  padding: 2rem;

  height: calc(100vh - 35rem) !important;
  overflow-y: scroll !important;

  label {
    font-size: 1.6rem !important;
  }

  .ant-form-item-label {
    text-align: start;
  }

  .ant-form-item {
    margin-bottom: 3rem !important;
  }

  .ant-select {
    width: 100% !important;
  }

  &__field {
    width: 100%;
    &-input {
      width: 100%;
    }
  }
}

.qbo-account-modal {
  // width: 600px !important;
  .ant-modal-content {
    padding: 0rem !important;
  }
}
